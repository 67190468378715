import React, { useState, useEffect } from 'react'
import { groupBy, reduce, sortBy } from 'lodash'
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"
import { Link } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"
import jump from 'jump.js'

import {
  LinkArrow
} from 'src/components/svgs'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'
import { useSetPage } from 'src/context/siteContext'

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
]


const textVariants = {
  open: {
    opacity: 1
  },
  closed: {
    opacity: 0
  }
}


export interface PageProps {
  pageContext: {
    spices: any[]
    defaultMeta: {}
    image: {}
    videoFileUrls: any[]
    videoFileUrl?: string
  }
}

const Spices = ({
  pageContext,
}: PageProps) => {
  const [active, setActive] = useState('')
  const [activeSpice, setActiveSpice] = useState(null)
  const setPage = useSetPage()
  const {
    spices,
    defaultMeta,
    image,
    videoFileUrls,
    videoFileUrl
  } = pageContext

  const groupList = reduce(groupBy(spices, 'content.main.title[0]'), (list: any[], value, key) => (
    [
      ...list,
      { letter: key, spices: value }
    ]
  ), [])

  useEffect(() =>{
    setPage('spice')
  }, [])

  // useEffect(() => {
  //   if (active) {
  //     console.log('spice', activeSpice)
  //   }
  // }, [activeSpice])

  const sortedList = sortBy(groupList, ['letter'])

  return (
    <div className="x">
      <SEO defaultMeta={defaultMeta} defaultTitle={'Occo Spice Index'} />
      <div className='row gutter--none df fw'>
        <div className='col c14 c7--800 pr'>
          <div className='psy top spice__inner z2 left y x df jcc aic'>
            <AnimatePresence>
              {activeSpice && (
                <>
                  <motion.div key={activeSpice._key} 
                      transition={{duration: 0.2}} initial={{ opacity: 0 }} animate={{ opacity: 1}} exit={{opacity: 0}} className='courier s15 spice__active-text z3 color--white p3 show--1000 pa'>
                    <motion.div variants={textVariants} >
                      {activeSpice.title} / {activeSpice.stats && (<span className='courier s14'>{activeSpice.stats[0].identifier} {activeSpice.stats[0].stat}</span>)}
                    </motion.div>
                  </motion.div>
                  <motion.div initial={{ opacity: 0 }} 
                      transition={{duration: 0.2}} animate={{ opacity: 1 }} exit={{opacity: 0}} className='pa z2 x y show--1000 spice__overlay top left'>
                    <motion.div  className='pa x y top left'>
                      <Image className='pa y object-fit x' imageId={activeSpice.secondaryImage} alt={activeSpice.title} />
                    </motion.div>
                  </motion.div>
                </>
              )}
            </AnimatePresence>
            {videoFileUrls ? (
              <video className='pa object-fit top x y left' autoPlay={true} muted={true} playsInline={true} loop={true}>
                <source src={videoFileUrls[Math.floor(Math.random() * videoFileUrls.length)  ]} />
              </video>
            ) : (
              <img src='/images/ingredients.jpg' alt='ingredients image' className='pa object-fit top x y left' />
            )}
            <div className={cx('p4 pr spice__world z4 color--white ')}>
              <h1 className='s40 s60--800 pt2 pt0--800 s100--1200'>Welcome to<br />Spice World</h1>
            </div>
          </div>
        </div>
        <div className='col c14 c7--800 pr'>
          <div className='spice__alphabet psy z3 top hide--800'>
            <div className='bg--white color--black bb p1 pl2 pr3 df jcb aic'>
              {alphabet.map((letter, i) => {
                const isPresent = sortedList.filter(elm => {
                  return elm.letter.toLowerCase() === letter
                })
                if (isPresent.length) {
                  return (
                    <button onClick={() => jump(`#${letter}`, {
                      offset: -80
                    })} key={letter} className='courier spice__button-letter bn caps s14'>{letter}</button>
                  )
                } else {
                  return <button disabled={true} className='courier bn spice__button-letter caps s14 o5' key={letter}>{letter}</button>
                }

              })}
            </div>
          </div>
          <div className='p2 p3--800 x ac bg--green color--white'>
            <div className='mt2 mt2--800 pt4--800 pl5--1200 pr5--1200 founders'>
              {sortedList.map((item: {
                letter: string
                spices: any[]
              }) => (
                <div key={item.letter} id={item.letter.toLowerCase()} className='spice__letter-blocks'>
                  <div className='spice__letter mt2 pr mb2'>
                    <div className='spice__letter-block df pr z1 jcc aic color--black'>{item.letter}</div>
                  </div>
                  {sortBy(item.spices, ['content.main.title']).map(spice => (
                    <div className={cx('mb1 mb2--800 pb1 pb2--800 ml2--800 spice__single bb', {
                      'is-open': active === spice._id
                    })} key={spice._id}>
                      <button onClick={() => {
                        if (active === spice._id) {
                          setActive('')
                          setActiveSpice(null)
                        } else {
                          setActiveSpice(null)
                          setActive(spice._id)
                          setTimeout(() => {
                            setActiveSpice(spice)
                          }, 400)
                        }
                      }} className='h2 s22 pl0 s40--800 mt0 pt0 regular spice__button pr0 bn df jcb aic x'>
                        <span className='color--white spice__button-text tl'>{spice.content.main.title}</span>
                        <div className='faq__circle df pr jcc aic'>
                          <svg className='di pa' width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M7.00281 12.7018L7.00281 1.29834" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.29962 7.00012L12.7031 7.00012" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </button>
                      <div className={cx('spice__more pl0 pb0', {
                        'mt2 pb1': active === spice._id
                      })}>
                          {spice.stats && spice.stats.map(stat => (
                            <div className='courier s14' key={stat._key}>{stat.identifier}: {stat.stat}</div>
                          ))}
                        {spice.content.main.text && (<BlockContent blocks={spice.content.main.text} serializers={Serializer} />)}
                        {spice.product && (
                          <Link className='caps link-underline bold' to={`/${spice.product._type === 'collection' ? 'collections' : 'products'}/${spice.product.slug.current}`}>{spice.linkCtaOverride ? spice.linkCtaOverride : `Shop ${spice.product.title}`} <LinkArrow /></Link>
                        )}

                        <Image className='db mt3 hide--800 object-fit x' imageId={spice.image} alt={spice.title} />
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className='psy z3 bt bl bottom x left show--800'>
            <div className='bg--white color--black p1 pl2 pr2 df jcb aic'>
              {alphabet.map((letter, i) => {
                const isPresent = sortedList.filter(elm => {
                  return elm.letter.toLowerCase() === letter
                })
                if (isPresent.length) {
                  return (
                    <button onClick={() => jump(`#${letter}`, {
                      offset: -80
                    })} key={letter} className='courier spice__button-letter bn caps s14'>{letter}</button>
                  )
                } else {
                  return <button disabled={true} className='courier bn spice__button-letter caps s14 o5' key={letter}>{letter}</button>
                }

              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spices